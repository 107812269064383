import React, { useEffect, useState } from 'react'
import Client from '../client/Client'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import SplitType from 'split-type'
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();
export default function About() {

    const [hoveredBox, setHoveredBox] = useState(null);


    

    const handleMouseEnter = (boxNumber) => {
        // setHoveredBox(boxNumber);
    };

    const handleMouseLeave = () => {
        // setHoveredBox(null);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useGSAP(() => {
        var el = document.querySelector('.banner_heading');
        var s = new SplitType(
            el, {
            type: "lines, words",
            linesClass: "ts-line"
        }
        );

        const tl = gsap.timeline({ delay: 0.5 });
        tl.addLabel('enter');

        tl.staggerFromTo(
            s.words,
            0.6, {
            yPercent: 100,
        }, {
            yPercent: 0,
            ease: 'Circ.easeOut'
        },
            0.2,
            'enter'
        );

        tl.staggerFromTo(
            s.words,
            0.6, {
            opacity: 0,
        }, {
            opacity: 1,
            ease: 'Power1.easeOut'
        },
            0.2,
            'enter'
        );
    })


    return (
        <>
            <section className='top p-l-r-1 inner-page-section -bg--bg-color1'>
                <div className='header-title'>
                    <div className='breadcrumb'>
                        <li>
                            <Link to='/'>Home.</Link>
                        </li>
                        <li className='active'>About</li>
                    </div>
                    <h2 className='heading-1 uppercase banner_heading'>
                        Delivering tailored solutions<br /> that drive real results
                    </h2>
                </div>
            </section>
            <section className='-bg--bg-color1 about_page top_1 p-l-r-1'>
                <div className='about-banner'>
                    <div className='about-section-2'>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            centeredSlides={true}
                            loop={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            className="mySwiper1"
                        >
                            <SwiperSlide>
                                <div className='about-img'>
                                    <img src={require('../../assets/images/banner-image-1.jpg')} alt='BCL1' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='about-img'>
                                    <img src={require('../../assets/images/banner-image-2.jpg')} alt='BCL1' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='about-img'>
                                    <img src={require('../../assets/images/banner-image-3.jpg')} alt='BCL1' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='about-img'>
                                    <img src={require('../../assets/images/banner-image-4.jpg')} alt='BCL1' />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='about-img'>
                                    <img src={require('../../assets/images/banner-image-5.jpg')} alt='BCL1' />
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </div>
                <div className='vission-mission-section top_1'>
                    <div className='grid  grid-cols-3 max-[768px]:grid-cols-2 max-[576px]:grid-cols-1 gap-10'>
                        <div
                            className={`v-m-box ${hoveredBox !== null && hoveredBox !== 1 ? 'fade' : 'hide'}`}
                            onMouseEnter={() => handleMouseEnter(1)}
                            onMouseLeave={handleMouseLeave}
                            data-aos="fade-up" data-aos-delay="300" data-aos-duration="10000"
                        >
                            <h4>MISSION</h4>
                            <p className='paragraph'>Empowering brands to reach their full potential through innovative strategies, creative excellence, and unwavering dedication to client success.</p>
                        </div>
                        <div
                            className={`v-m-box ${hoveredBox !== null && hoveredBox !== 2 ? 'fade' : 'hide'}`}
                            onMouseEnter={() => handleMouseEnter(2)}
                            onMouseLeave={handleMouseLeave}
                            data-aos="fade-up" data-aos-duration="10000" data-aos-delay="600"
                        >
                            <h4>VISION</h4>
                            <p className='paragraph'>To be the catalyst for transformative growth, shaping brands that inspire and resonate with audiences worldwide.</p>
                        </div>
                        <div
                            className={`v-m-box ${hoveredBox !== null && hoveredBox !== 3 ? 'fade' : 'hide'}`}
                            onMouseEnter={() => handleMouseEnter(3)}
                            onMouseLeave={handleMouseLeave}
                            data-aos="fade-up" data-aos-duration="10000"
                            data-aos-delay="900"
                        >
                            <h4>VALUE</h4>
                            <p className='paragraph'>We strive for excellence in all that we do, setting high standards and consistently delivering exceptional results.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='-bg--bg-color1 team_section top_1 bottom_1 p-l-r-1'>
                <div className='header-title'>
                    <h3 className='heading-1 uppercase'>
                        Meet Your<br /> Dream Team
                    </h3>
                </div>
                <div className='grid grid-flow-row-dense grid-cols-3 max-[768px]:grid-cols-2 max-[576px]:grid-cols-1 gap-14 martop'>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/surendra.jpg')} alt='Rectangle 15' />
                        </div>
                        <div className='team_details'>
                            <h4>Surendra Singh</h4>
                            <h3>Managing Director</h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/ishani.jpg')} alt='ishani' />
                        </div>
                        <div className='team_details'>
                            <h4>Ishani Ghosh</h4>
                            <h3>Business Head (North & East) </h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/sanjeevjha.jpg')} alt='sanjeevjha' />
                        </div>
                        <div className='team_details'>
                            <h4>Sanjeev Jha</h4>
                            <h3>Finance Head</h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/abhishekmehata.jpg')} alt='abhishekmehata' />
                        </div>
                        <div className='team_details'>
                            <h4>Abhishek Mehta</h4>
                            <h3>HR Director</h3>
                        </div>
                    </div>
                    {/* <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/mayuresan.jpg')} alt='mayuresan' />
                        </div>
                        <div className='team_details'>
                            <h4>Mayuresan Gurukkal</h4>
                            <h3>Business Head South </h3>
                        </div>
                    </div> */}
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/surajit.jpg')} alt='surajit' />
                        </div>
                        <div className='team_details'>
                            <h4>Surajit Banik</h4>
                            <h3>Business Director (East) </h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/yatendra.jpg')} alt='yatendra' />
                        </div>
                        <div className='team_details'>
                            <h4>Yatendra Joshi</h4>
                            <h3>Business Director (North) </h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/jitendra.jpg')} alt='jitendra' />
                        </div>
                        <div className='team_details'>
                            <h4>Jitendra Jeena</h4>
                            <h3>Business Director (North)</h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/prasad.jpg')} alt='prasad' />
                        </div>
                        <div className='team_details'>
                            <h4>Prasad Ekambaram</h4>
                            <h3>Senior Business Director (South)</h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/rajnikanth.jpg')} alt='rajnikanth' />
                        </div>
                        <div className='team_details'>
                            <h4>RajiniKanth M</h4>
                            <h3>Senior Business Director (South)</h3>
                        </div>
                    </div>
                    {/* <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/mdkalim.jpg')} alt='mdkalim' />
                        </div>
                        <div className='team_details'>
                            <h4>Md. Kalim</h4>
                            <h3>Operations - South </h3>
                        </div>
                    </div> */}
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/praveen.jpg')} alt='praveen' />
                        </div>
                        <div className='team_details'>
                            <h4>Praveen Rao</h4>
                            <h3>CEO - 3% Collective</h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/pradeep.jpg')} alt='pradeep' />
                        </div>
                        <div className='team_details'>
                            <h4>Pradeep Singh </h4>
                            <h3>COO - 3% Collective</h3>
                        </div>
                    </div>
                    {/* <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/karishma.jpg')} alt='karishma' />
                        </div>
                        <div className='team_details'>
                            <h4>Karishma Dhutia</h4>
                            <h3>Business Director (Mumbai) </h3>
                        </div>
                    </div> */}
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/krishna.jpg')} alt='krishna' />
                        </div>
                        <div className='team_details'>
                            <h4>Krishna Mugatkar</h4>
                            <h3>Business Director (Pune)</h3>
                        </div>
                    </div>
                    {/* <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/eina.jpg')} alt='eina' />
                        </div>
                        <div className='team_details'>
                            <h4>Eina R Warrier</h4>
                            <h3>Associate Creative Director - 3% Collective</h3>
                        </div>
                    </div> */}
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/siddharth.jpg')} alt='siddharth' />
                        </div>
                        <div className='team_details'>
                            <h4>Siddharth Mishra</h4>
                            <h3>Business Director (Gujarat) </h3>
                        </div>
                    </div>
                    {/* <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/leena.jpg')} alt='leena' />
                        </div>
                        <div className='team_details'>
                            <h4>Leena Sharma</h4>
                            <h3>Business Head - OOH</h3>
                        </div>
                    </div> */}
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/manas.jpg')} alt='manas' />
                        </div>
                        <div className='team_details'>
                            <h4>Manas Sareen</h4>
                            <h3>Business Head - Rural </h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/nitin.jpg')} alt='nitin' />
                        </div>
                        <div className='team_details'>
                            <h4>Nitin Saini</h4>
                            <h3>Business Director - Merchandising </h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/atul.jpg')} alt='atul' />
                        </div>
                        <div className='team_details'>
                            <h4>Atul Kumar</h4>
                            <h3>Business Director - Printing & POSM</h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/rajat.jpg')} alt='rajat' />
                        </div>
                        <div className='team_details'>
                            <h4>Rajat Chakravarty</h4>
                            <h3>Business Director - OOH </h3>
                        </div>
                    </div>
                    {/* <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/mukesh.jpg')} alt='mukesh' />
                        </div>
                        <div className='team_details'>
                            <h4>Mukesh Lal</h4>
                            <h3>Business Director Printing & POSM </h3>
                        </div>
                    </div> */}
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/vinay.jpg')} alt='vinay' />
                        </div>
                        <div className='team_details'>
                            <h4>Vinay Gautam</h4>
                            <h3> Creative Head  </h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/kajal.jpg')} alt='kajal' />
                        </div>
                        <div className='team_details'>
                            <h4>Kajal Singh</h4>
                            <h3>Vertical Head - Lead Generation </h3>
                        </div>
                    </div>
                    <div className='team_box'>
                        <div className='team_profile_img'>
                            <img src={require('../../assets/images/team/apoorva.jpg')} alt='apoorva' />
                        </div>
                        <div className='team_details'>
                            <h4>Apoorva Panday</h4>
                            <h3>Vertical Head - Lead Generation</h3>
                        </div>
                    </div>

                </div>
            </section>
            <Client />
        </>
    )
}
